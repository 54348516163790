<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row class="match-height">
        <b-col>
          <b-card>
            <validation-observer
              #default="{ handleSubmit }"
              ref="supplierFormObserver"
            >
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <h3
                  v-if="$route.name !== 'add-new-supplier-to-establishment' && currentSupplier"
                  class="d-flex justify-content-center align-items-center mb-2"
                >
                  Proveedor {{ currentSupplier.name }}
                </h3>

                <validation-provider
                  v-else
                  #default="validationContext"
                  name="supplierName"
                >
                  <b-form-group
                    label="Nombre del proveedor"
                    label-for="supplierName"
                  >
                    <b-input-group>
                      <b-form-input
                        id="supplierName"
                        v-model="adminSupplier.name"
                        autofocus
                        trim
                        placeholder=""
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <h4 class="mb-2">
                  Información adicional del proveedor
                </h4>

                <!-- NAME -->
                <validation-provider
                  #default="validationContext"
                  name="Nombre"
                >
                  <b-form-group
                    label="Nombre de contacto"
                    label-for="name"
                  >
                    <b-input-group>
                      <b-form-input
                        id="name"
                        v-model="establishment_supplier.contact_name"
                        autofocus
                        trim
                        placeholder=""
                      />
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- EMAIL -->
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-input-group>
                      <b-form-input
                        id="email"
                        v-model="establishment_supplier.contact_email"
                        type="email"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- PHONE NUMBER -->
                <validation-provider
                  #default="validationContext"
                  name="Phone"
                >
                  <b-form-group
                    label="Teléfono"
                    label-for="phone"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="fas fa-phone" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="phone"
                        v-model="establishment_supplier.phone_number"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- FISCAL ID -->
                <validation-provider
                  #default="validationContext"
                  name="fiscal"
                >
                  <b-form-group
                    label="RFC"
                    label-for="fiscal"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="far fa-id-badge" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="fiscal"
                        v-model="establishment_supplier.fiscal_id"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- BANK NUMBER -->
                <validation-provider
                  #default="validationContext"
                  name="Bank"
                >
                  <b-form-group
                    label="CLABE Interbancaria"
                    label-for="bank"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <I class="fas fa-money-check-alt" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="bank"
                        v-model="establishment_supplier.bank_unique_id"
                        trim
                      />
                    </b-input-group>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- STORES SELECT -->
                <b-form-group
                  v-if="$route.name !== 'edit-establishment-supplier'"
                  label="Seleccione las tiendas que pertenece el proovedor"
                  label-for="stores"
                >
                  <v-select
                    v-if="establishmentStores"
                    id="stores"
                    v-model="stores"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="establishmentStores"
                    :reduce="option => option.id"
                  />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    Agregar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :to="{ name: 'establishment-view', params: { id: $route.params.establishment_id } }"
                  >
                    Cancelar
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      stores: null,
      adminSupplier: {
        name: null,
      },
      establishmentStores: null,
      establishment_supplier: {
        establishment_id: null,
        supplier_id: null,
        contact_name: '',
        contact_email: '',
        phone_number: '',
        fiscal_id: '',
        bank_unique_id: '',
      },
    }
  },
  computed: {
    ...mapGetters('suppliers', ['currentSupplier', 'currentEstablishmentSupplier']),
  },
  beforeMount() {
    this.fetchEstablishment(this.$route.params.establishment_id)
      .then(response => {
        this.establishmentStores = response.stores_attributes
      })
    if (this.$route.name === 'edit-establishment-supplier') {
      this.fetchEstablishmentSupplier(this.$route.params.supplier_id)
        .then(response => {
          this.establishment_supplier = response
          this.fetchSupplier(this.establishment_supplier.supplier_attributes.id)
        })
    } else if (this.$route.name === 'add-supplier-to-establishment') {
      this.fetchSupplier(this.$route.params.supplier_id)
    }
  },
  methods: {
    ...mapActions('suppliers', [
      'addSupplier',
      'addEstablishmentSupplier',
      'addStoreSupplier',
      'fetchSupplier',
      'fetchEstablishmentSupplier',
      'editEstablishmentSupplier',
    ]),
    ...mapActions('establishments', ['fetchEstablishment']),
    onSubmit() {
      this.$refs.supplierFormObserver.validate().then(success => {
        if (success) {
          this.establishment_supplier.establishment_id = this.$route.params.establishment_id
          if (this.$route.name === 'add-new-supplier-to-establishment') {
            this.addSupplier(this.adminSupplier)
              .then(response => {
                this.establishment_supplier.supplier_id = response.id
                this.addEstablishmentSupplier(this.establishment_supplier)
                  .then(resp => {
                    this.$router.push({ name: 'establishment-view', params: { id: this.$route.params.establishment_id } })
                    // eslint-disable-next-line camelcase
                    const establishment_id = resp.id
                    if (this.stores != null) {
                      this.stores.forEach(store => {
                        // eslint-disable-next-line camelcase
                        const store_supplier = {
                          store_id: store,
                          establishment_supplier_id: establishment_id,
                        }
                        this.addStoreSupplier(store_supplier)
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: error.res.data.messages.supplier[0],
                      },
                    })
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages.name[0],
                  },
                })
              })
          } else if (this.$route.name === 'add-supplier-to-establishment') {
            this.establishment_supplier.supplier_id = this.$route.params.supplier_id
            this.addEstablishmentSupplier(this.establishment_supplier)
              .then(response => {
                this.$router.push({ name: 'establishment-view', params: { id: this.$route.params.establishment_id } })
                // eslint-disable-next-line camelcase
                const establishment_id = response.id
                if (this.stores != null) {
                  this.stores.forEach(store => {
                    // eslint-disable-next-line camelcase
                    const store_supplier = {
                      store_id: store,
                      establishment_supplier_id: establishment_id,
                    }
                    this.addStoreSupplier(store_supplier)
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages.supplier[0],
                  },
                })
              })
          } else {
            this.establishment_supplier.supplier_id = this.$route.params.supplier_id
            this.editEstablishmentSupplier(this.establishment_supplier)
              .then(() => this.$router.push({ name: 'establishment-view', params: { id: this.$route.params.establishment_id } }))
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages.supplier[0],
                  },
                })
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
